<template>
    <div class="">
        <div class="praesidentinnen-title mb-5" >
            <h1 class="sz-kath-title">Präsidentinnen und Präsidenten</h1>
        </div>
        <div class="praesidentinnen-wrapper">
            <div v-for="(Praesident, i) in PraesidentInnen" :key="i"
                    >
                <div class="praesidentinnen-card-wrapper kath-sz-card">
                    <div>
                        <a :href="Praesident.locationLink" class="link font-weight-bold" target="_blank">
                            {{Praesident.location}}
                        </a>

                    </div>
                    <a    class="link" :href="Praesident.link"  target="_blank">
                        {{Praesident.leader}}
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Praesidentinnen-und-Praesidenten",
        computed: {
            PraesidentInnen () {
                return this.$store.getters.PraesidentInnen
            }
        },
        methods: {
            openLeaderAdress(link){
                window.open(link, '_blank');
            }
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>
a.link {
    color: black;
    text-decoration: none;
}

a.link:hover{
    text-decoration: underline;
}

a.link:visited{
    color: rgb(49, 0, 0);
}

</style>